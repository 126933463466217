var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _setup.ruleForm.cooperationStatusName
        ? _c("div", { staticClass: "states-name" }, [
            _c("div", { staticClass: "margin-b10" }, [
              _vm._v(
                " 状态：" + _vm._s(_setup.ruleForm.cooperationStatusName) + " "
              ),
            ]),
            +_setup.ruleForm.cooperationStatus === 2
              ? _c("div", [
                  _vm._v(
                    " 原因：" + _vm._s(_setup.ruleForm.approvalRemark) + " "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: {
            model: _setup.ruleForm,
            rules: _setup.rules,
            "label-width": "250px",
            size: "small",
          },
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v(" 企业信息 ")]),
          _c("el-form-item", { attrs: { label: "主体类型" } }, [
            _vm._v(" " + _vm._s(_setup.ruleForm.supplierTypeName) + " "),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "营业执照" } },
            [
              _setup.ruleForm.businessLicenseImagePaths
                ? _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      fit: "contain",
                      src: _setup.ruleForm.businessLicenseImagePaths[0],
                      "preview-src-list":
                        _setup.ruleForm.businessLicenseImagePaths,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "统一社会信用代码" } }, [
            _vm._v(" " + _vm._s(_setup.ruleForm.uscc) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "企业名称" } }, [
            _vm._v(" " + _vm._s(_setup.ruleForm.suppliersName) + " "),
          ]),
          _c("el-form-item", { attrs: { label: "所属地区" } }, [
            _vm._v(" " + _vm._s(_setup.ruleForm.address) + " "),
          ]),
          _c("div", { staticClass: "title" }, [_vm._v(" 供应链能力 ")]),
          _c(
            "el-form-item",
            { attrs: { label: "供货品类", prop: "category" } },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  placeholder: "请输入供货品类",
                  size: "small",
                  maxlength: "30",
                  "show-word-limit": "",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.category,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "category", $$v)
                  },
                  expression: "ruleForm.category",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生产/品控人", prop: "productionQualityCount" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 99999999,
                  placeholder: "请输入生产/品控人",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.productionQualityCount,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "productionQualityCount", $$v)
                  },
                  expression: "ruleForm.productionQualityCount",
                },
              }),
              _vm._v(" 人 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服人数", prop: "customerServiceCount" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 99999999,
                  placeholder: "请输入客服人数",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.customerServiceCount,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "customerServiceCount", $$v)
                  },
                  expression: "ruleForm.customerServiceCount",
                },
              }),
              _vm._v(" 人 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生产/发货场面积", prop: "productionArea" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 99999999,
                  placeholder: "请输入生产/发货场面积",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.productionArea,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "productionArea", $$v)
                  },
                  expression: "ruleForm.productionArea",
                },
              }),
              _vm._v(" 平 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "（冷链）仓储", prop: "coldStorage" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _setup.disabled },
                  model: {
                    value: _setup.ruleForm.coldStorage,
                    callback: function ($$v) {
                      _vm.$set(_setup.ruleForm, "coldStorage", $$v)
                    },
                    expression: "ruleForm.coldStorage",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 有 ")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v(" 无 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单日最大发货量", prop: "maxDailyShipment" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 99999999,
                  placeholder: "请输入单日最大发货量",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.maxDailyShipment,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "maxDailyShipment", $$v)
                  },
                  expression: "ruleForm.maxDailyShipment",
                },
              }),
              _vm._v(" 单 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "合作快递", prop: "cooperativeCourier" } },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  placeholder: "请输入合作快递",
                  size: "small",
                  maxlength: "30",
                  "show-word-limit": "",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.cooperativeCourier,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "cooperativeCourier", $$v)
                  },
                  expression: "ruleForm.cooperativeCourier",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货品来源", prop: "productSource" } },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  placeholder: "请输入货品来源",
                  size: "small",
                  maxlength: "30",
                  "show-word-limit": "",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.productSource,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "productSource", $$v)
                  },
                  expression: "ruleForm.productSource",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "货品最大日供货量", prop: "maxDailySupply" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 99999999,
                  placeholder: "请输入货品最大日供货量",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.maxDailySupply,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "maxDailySupply", $$v)
                  },
                  expression: "ruleForm.maxDailySupply",
                },
              }),
              _vm._v(" 单 "),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否涉及纠纷/诉讼/仲裁/行政处罚等",
                prop: "disputeLitigation",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _setup.disabled },
                  model: {
                    value: _setup.ruleForm.disputeLitigation,
                    callback: function ($$v) {
                      _vm.$set(_setup.ruleForm, "disputeLitigation", $$v)
                    },
                    expression: "ruleForm.disputeLitigation",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v(" 有 ")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v(" 无 ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v(" 联系信息 ")]),
          _c(
            "el-form-item",
            { attrs: { label: "联系人姓名", prop: "contactPersonName" } },
            [
              _c("el-input", {
                staticClass: "w400",
                attrs: {
                  placeholder: "请输入联系人姓名",
                  size: "small",
                  maxlength: "30",
                  "show-word-limit": "",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.contactPersonName,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "contactPersonName", $$v)
                  },
                  expression: "ruleForm.contactPersonName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人手机", prop: "contactPersonMobile" } },
            [
              _c("el-input-number", {
                staticClass: "w400",
                attrs: {
                  controls: false,
                  precision: 0,
                  min: 1,
                  max: 18888888888888888,
                  placeholder: "请输入联系人手机",
                  size: "small",
                  disabled: _setup.disabled,
                },
                model: {
                  value: _setup.ruleForm.contactPersonMobile,
                  callback: function ($$v) {
                    _vm.$set(_setup.ruleForm, "contactPersonMobile", $$v)
                  },
                  expression: "ruleForm.contactPersonMobile",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "padding-l20 padding-t20" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _setup.onBack } },
            [_vm._v(" 返 回 ")]
          ),
          _setup.query.type === "edit" || _setup.query.type === "add"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onSubmit },
                },
                [_vm._v(" 提 交 ")]
              )
            : _vm._e(),
          _setup.query.type === "audit"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _setup.auditInfo.show = true
                    },
                  },
                },
                [_vm._v(" 审 核 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核供应链合作",
            visible: _setup.auditInfo.show,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_setup.auditInfo, "show", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "auditInfoRef",
                  attrs: { model: _setup.auditInfo, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "审核结果",
                        prop: "approvalResult",
                        rules: [
                          {
                            required: true,
                            message: "请选择审核结果",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _setup.auditInfo.approvalResult,
                            callback: function ($$v) {
                              _vm.$set(_setup.auditInfo, "approvalResult", $$v)
                            },
                            expression: "auditInfo.approvalResult",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 通过 "),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(" 拒绝 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _setup.auditInfo.approvalResult === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "拒绝原因",
                            prop: "approvalRemark",
                            rules: [
                              {
                                required: true,
                                message: "请输入拒绝原因",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入拒绝原因",
                              type: "textarea",
                              size: "small",
                              maxlength: "50",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _setup.auditInfo.approvalRemark,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.auditInfo,
                                  "approvalRemark",
                                  $$v
                                )
                              },
                              expression: "auditInfo.approvalRemark",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _setup.auditInfo.show = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onAuditSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }