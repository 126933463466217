<script setup>
import {
  ref, reactive, getCurrentInstance, onMounted,
} from 'vue';

const app = getCurrentInstance().proxy;
const { query } = app.$route;
const disabled = ['show', 'audit'].includes(query.type);

const checkPhone = (rule, value, callback) => {
  const regex = /^1\d{10}$/;
  if (regex.test(value)) {
    callback();
  } else {
    callback(new Error('请输入密码'));
  }
};
const rules = {
  category: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  productionQualityCount: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  customerServiceCount: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  productionArea: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  coldStorage: [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  maxDailyShipment: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  cooperativeCourier: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  productSource: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  maxDailySupply: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  disputeLitigation: [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  contactPersonName: [
    { required: true, message: '请输入内容', trigger: 'blur' },
  ],
  contactPersonMobile: [
    { required: true, message: '请输入联系人手机', trigger: 'blur' },
    { validator: checkPhone, message: '请输入正确手机格式', trigger: 'blur' },
  ],
};

const ruleFormRef = ref(null);
const auditInfoRef = ref(null);

const ruleForm = ref({
  supplierTypeName: '',
  businessLicenseImagePaths: null,
  uscc: '',
  suppliersName: '',
  address: '',

  category: '',
  productionQualityCount: undefined,
  customerServiceCount: undefined,
  productionArea: undefined,
  coldStorage: undefined, // 仓储 0：有 1：无
  maxDailyShipment: undefined,
  cooperativeCourier: '',
  productSource: '',
  maxDailySupply: undefined,
  disputeLitigation: undefined,
  contactPersonName: '',
  contactPersonMobile: undefined,
});
const auditInfo = reactive({
  show: false,
  approvalResult: '',
  approvalRemark: '',
});

function onSubmit() {
  ruleFormRef.value.validate((valid) => {
    if (!valid) return;
    app.$confirm('是否确认提交审核?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const res = await app.$axios.post(app.$api.preferred_supplychain.apply, ruleForm.value);
      if (res.code !== 0) return;
      app.$message.success('操作成功！');
      app.$router.go(-1);
    }).catch(() => {});
  });
}
function onAuditSubmit() {
  auditInfoRef.value.validate((valid) => {
    if (!valid) return;
    app.$confirm('是否确认审核?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const res = await app.$axios.post(app.$api.preferred_supplychain.audit, {
        approvalRemark: auditInfo.approvalRemark,
        approvalResult: auditInfo.approvalResult,
        id: ruleForm.value.id,
      });
      if (res.code !== 0) return;
      app.$message.success('操作成功！');
      app.$router.go(-1);
    }).catch(() => {});
  });
}
function onBack() {
  app.$router.go(-1);
}
async function getDetail() {
  const { suppliersId } = query;
  const res = await app.$axios.get(app.$api.preferred_supplychain.detail, {
    params: {
      suppliersId,
    },
  });
  if (res.code !== 0) return;
  for (const key in res.data) {
    if (res.data[key] || res.data[key] === 0) {
      ruleForm.value[key] = res.data[key];
    }
  }
  console.log(ruleForm.value);
}
onMounted(() => {
  getDetail();
});
</script>

<template>
  <div class="outer">
    <div
      v-if="ruleForm.cooperationStatusName"
      class="states-name"
    >
      <div class="margin-b10">
        状态：{{ ruleForm.cooperationStatusName }}
      </div>
      <div v-if="+ruleForm.cooperationStatus === 2">
        原因：{{ ruleForm.approvalRemark }}
      </div>
    </div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="250px"
      size="small"
    >
      <div class="title">
        企业信息
      </div>
      <el-form-item
        label="主体类型"
      >
        {{ ruleForm.supplierTypeName }}
      </el-form-item>
      <el-form-item
        label="营业执照"
      >
        <el-image
          v-if="ruleForm.businessLicenseImagePaths"
          style="width: 100px; height: 100px"
          fit="contain"
          :src="ruleForm.businessLicenseImagePaths[0]"
          :preview-src-list="ruleForm.businessLicenseImagePaths"
        />
      </el-form-item>
      <el-form-item
        label="统一社会信用代码"
      >
        {{ ruleForm.uscc }}
      </el-form-item>
      <el-form-item
        label="企业名称"
      >
        {{ ruleForm.suppliersName }}
      </el-form-item>
      <el-form-item
        label="所属地区"
      >
        {{ ruleForm.address }}
      </el-form-item>

      <div class="title">
        供应链能力
      </div>
      <el-form-item
        label="供货品类"
        prop="category"
      >
        <el-input
          v-model="ruleForm.category"
          class="w400"
          placeholder="请输入供货品类"
          size="small"
          maxlength="30"
          show-word-limit
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="生产/品控人"
        prop="productionQualityCount"
      >
        <el-input-number
          v-model="ruleForm.productionQualityCount"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="99999999"
          placeholder="请输入生产/品控人"
          size="small"
          :disabled="disabled"
        />
        人
      </el-form-item>
      <el-form-item
        label="客服人数"
        prop="customerServiceCount"
      >
        <el-input-number
          v-model="ruleForm.customerServiceCount"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="99999999"
          placeholder="请输入客服人数"
          size="small"
          :disabled="disabled"
        />
        人
      </el-form-item>
      <el-form-item
        label="生产/发货场面积"
        prop="productionArea"
      >
        <el-input-number
          v-model="ruleForm.productionArea"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="99999999"
          placeholder="请输入生产/发货场面积"
          size="small"
          :disabled="disabled"
        />
        平
      </el-form-item>
      <el-form-item
        label="（冷链）仓储"
        prop="coldStorage"
      >
        <el-radio-group
          v-model="ruleForm.coldStorage"
          :disabled="disabled"
        >
          <el-radio :label="1">
            有
          </el-radio>
          <el-radio :label="0">
            无
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="单日最大发货量"
        prop="maxDailyShipment"
      >
        <el-input-number
          v-model="ruleForm.maxDailyShipment"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="99999999"
          placeholder="请输入单日最大发货量"
          size="small"
          :disabled="disabled"
        />
        单
      </el-form-item>
      <el-form-item
        label="合作快递"
        prop="cooperativeCourier"
      >
        <el-input
          v-model="ruleForm.cooperativeCourier"
          class="w400"
          placeholder="请输入合作快递"
          size="small"
          maxlength="30"
          show-word-limit
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="货品来源"
        prop="productSource"
      >
        <el-input
          v-model="ruleForm.productSource"
          class="w400"
          placeholder="请输入货品来源"
          size="small"
          maxlength="30"
          show-word-limit
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="货品最大日供货量"
        prop="maxDailySupply"
      >
        <el-input-number
          v-model="ruleForm.maxDailySupply"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="99999999"
          placeholder="请输入货品最大日供货量"
          size="small"
          :disabled="disabled"
        />
        单
      </el-form-item>
      <el-form-item
        label="是否涉及纠纷/诉讼/仲裁/行政处罚等"
        prop="disputeLitigation"
      >
        <el-radio-group
          v-model="ruleForm.disputeLitigation"
          :disabled="disabled"
        >
          <el-radio :label="1">
            有
          </el-radio>
          <el-radio :label="0">
            无
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="title">
        联系信息
      </div>
      <el-form-item
        label="联系人姓名"
        prop="contactPersonName"
      >
        <el-input
          v-model="ruleForm.contactPersonName"
          class="w400"
          placeholder="请输入联系人姓名"
          size="small"
          maxlength="30"
          show-word-limit
          :disabled="disabled"
        />
      </el-form-item>
      <el-form-item
        label="联系人手机"
        prop="contactPersonMobile"
      >
        <el-input-number
          v-model="ruleForm.contactPersonMobile"
          class="w400"
          :controls="false"
          :precision="0"
          :min="1"
          :max="18888888888888888"
          placeholder="请输入联系人手机"
          size="small"
          :disabled="disabled"
        />
      </el-form-item>
    </el-form>

    <div class="padding-l20 padding-t20">
      <el-button
        size="small"
        @click="onBack"
      >
        返 回
      </el-button>
      <el-button
        v-if="query.type === 'edit' || query.type === 'add'"
        type="primary"
        size="small"
        @click="onSubmit"
      >
        提 交
      </el-button>
      <el-button
        v-if="query.type === 'audit'"
        type="primary"
        size="small"
        @click="auditInfo.show = true;"
      >
        审 核
      </el-button>
    </div>

    <!-- 审核弹窗 -->
    <el-dialog
      title="审核供应链合作"
      :visible.sync="auditInfo.show"
      width="500px"
    >
      <div>
        <el-form
          ref="auditInfoRef"
          :model="auditInfo"
          label-width="100px"
        >
          <el-form-item
            label="审核结果"
            prop="approvalResult"
            :rules="[
              { required: true, message: '请选择审核结果', trigger: 'change' },
            ]"
          >
            <el-radio-group v-model="auditInfo.approvalResult">
              <el-radio :label="1">
                通过
              </el-radio>
              <el-radio :label="2">
                拒绝
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="auditInfo.approvalResult === 2"
            label="拒绝原因"
            prop="approvalRemark"
            :rules="[
              { required: true, message: '请输入拒绝原因', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="auditInfo.approvalRemark"
              placeholder="请输入拒绝原因"
              type="textarea"
              size="small"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="auditInfo.show = false"
        >取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="onAuditSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.states-name {
  font-size: 22px;
  text-align: center;
}
.title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
  padding-left: 50px;
}
:deep(.el-input-number .el-input__inner) {
  text-align: left;
}
</style>
